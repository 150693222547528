<template>
  <div v-if="init" class="web-pages-wrapper">
    <WebPagesList v-if="!editing && !editingMenus && !editingAppSlider" />
    <WebPagesEditor v-else-if="!editingMenus && !editingAppSlider && editing" />

    <WebPagesMenuEditor v-if="editingMenus !== false" />

    <AppSliderEditor v-if="!editing && editingAppSlider" />

    <button
      class="web-pages-edit-menus-button"
      @click="$store.commit('webPages/setEditingMenus', true)"
      v-if="!editing && !editingMenus && !editingAppSlider"
    >
      Edit menus
    </button>

    <button
      class="web-pages-edit-menus-button"
      @click="$store.dispatch('webPages/setupEditingAppSlider')"
      v-if="!editing && !editingMenus && !editingAppSlider"
    >
      Edit app slider
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WebPages",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      init: (state) => state.webPages.init,
      editing: (state) => state.webPages.editing,
      editingMenus: (state) => state.webPages.editingMenus,
      editingAppSlider: (state) => state.webPages.editingAppSlider,
    }),
  },
  mounted() {},
  async created() {
    if (!this.init) {
      await this.$store.dispatch("webPages/bindWebPages");
      await this.$store.dispatch("webPages/bindMenus");
      this.$store.commit("webPages/setInit", true);
    }
  },
  beforeDestroy() {
    this.$store.dispatch("webPages/unbindWebPages");
    this.$store.commit("webPages/setInit", false);
    this.$store.commit("webPages/setEditing", false);
  },
  methods: {},
};
</script>
